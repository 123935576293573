<script>
  import serverConfig   from '@/helpers/config';
  import Swal from "sweetalert2";
  import axios from 'axios';

  import {
    layoutComputed
  } from "@/state/helpers";
  import {
    SimpleBar
  } from "simplebar-vue3";
  
  export default {
    components: {
      SimpleBar
    },
    data() {
      return {
        config:serverConfig,
        menulist:[],
        settings: {
          minScrollbarLength: 60,
        },
      };
    },
    computed: {
      ...layoutComputed,
      layoutType: {
        get() {
          return this.$store ? this.$store.state.layout.layoutType : {} || {};
        },
      },
      menuPermissions: {
        get() {
          return this.$store ? this.$store.state.auth.pages_setting : {} || {};
        },
      },
    },
  
    watch: {
      $route: {
        handler: "onRoutechange",
        immediate: true,
        deep: true,
      },
    },
  
    mounted() {
    if (document.querySelectorAll(".navbar-nav .collapse")) {
      let collapses = document.querySelectorAll(".navbar-nav .collapse");

      collapses.forEach((collapse) => {
        // Hide sibling collapses on `show.bs.collapse`
        collapse.addEventListener("show.bs.collapse", (e) => {
          e.stopPropagation();
          let closestCollapse = collapse.parentElement.closest(".collapse");
          if (closestCollapse) {
            let siblingCollapses =
              closestCollapse.querySelectorAll(".collapse");
            siblingCollapses.forEach((siblingCollapse) => {
              if (siblingCollapse.classList.contains("show")) {
                siblingCollapse.classList.remove("show");
              }
            });
          } else {
            let getSiblings = (elem) => {
              // Setup siblings array and get the first sibling
              let siblings = [];
              let sibling = elem.parentNode.firstChild;
              // Loop through each sibling and push to the array
              while (sibling) {
                if (sibling.nodeType === 1 && sibling !== elem) {
                  siblings.push(sibling);
                }
                sibling = sibling.nextSibling;
              }
              return siblings;
            };
            let siblings = getSiblings(collapse.parentElement);
            siblings.forEach((item) => {
              if (item.childNodes.length > 2)
                item.firstElementChild.setAttribute("aria-expanded", "false");
              let ids = item.querySelectorAll("*[id]");
              ids.forEach((item1) => {
                item1.classList.remove("show");
                if (item1.childNodes.length > 2) {
                  let val = item1.querySelectorAll("ul li a");

                  val.forEach((subitem) => {
                    if (subitem.hasAttribute("aria-expanded"))
                      subitem.setAttribute("aria-expanded", "false");
                  });
                }
              });
            });
          }
        });

        // Hide nested collapses on `hide.bs.collapse`
        collapse.addEventListener("hide.bs.collapse", (e) => {
          e.stopPropagation();
          let childCollapses = collapse.querySelectorAll(".collapse");
          childCollapses.forEach((childCollapse) => {
            let childCollapseInstance = childCollapse;
            childCollapseInstance.hide();
          });
        });
      });
    }
    this.getMenu()
  },

  methods: {
    axiosInterceptor(){
      axios.interceptors.response.use(
        (response) => {
          return response;
        },
        (error) => {
            if (error.response && error.response.status === 401) {
              Swal.fire({
                title:"Accesso non autorizzato",
                text:"Mi spiace, ma l'accesso a questa risorsa non è autorizzato, probabilmente a causa della sessione scaduta o per mancanza dei requisiti",
                icon:"warning",
                showCancelButton:false,
                showConfirmButton:false,
                timer:2000,
              })
                this.$router.push('/login')
                if(localStorage.getItem('tk')){
                  localStorage.removeItem('tk')
                }
                return Promise.reject('Unauthorized access');
            } else if (error.response && error.response.status  === 404) {
                Swal.fire({
                  title:"Nessun risultato trovato",
                  text:"Mi spiace, nessun risultato trovato",
                  icon:"error",
                  showCancelButton:false,
                  showConfirmButton:false,
                  timer:2000,
                })
                return Promise.reject('The server cannot find the requested resource');
            }  else if (error.response && error.response.status  === 412) {
              Swal.fire({
                title:"Credenziali mancanti",
                text:"Inserisci delle credenziali valide, per favore.",
                icon:"warning",
                showCancelButton:false,
                showConfirmButton:false,
                timer:2000,
              })
              return Promise.reject('The server cannot find the requested resource');
          } else if (error.response && error.response.status  === 423) {
                Swal.fire({
                  title:"Risorsa non accessibile",
                  text:"Il contenuto al quale stai tentando di accedere non è accessibile.",
                  icon:"error",
                  showCancelButton:false,
                  showConfirmButton:false,
                  timer:2000,
                })
                this.$router.push('/login')
                if(localStorage.getItem('tk')){
                  localStorage.removeItem('tk')
                }                
                return Promise.reject('The server cannot find the requested resource');
            }
            else if (error.response && error.response.status  === 500) {
              Swal.fire({
                title:"Errore Server",
                text:"Mi spiace, ma riscontriamo degli errori sul server.",
                icon:"error",
                showCancelButton:false,
                showConfirmButton:false,
                timer:2000,
              })
                return Promise.reject('Internal Server Error');
            }
            else if (error.response && error.response.status  === 502) {
                Swal.fire({
                  title:"Errore Server",
                  text:"Mi spiace, ma riscontriamo degli errori sul server.",
                  icon:"error",
                  showCancelButton:false,
                  showConfirmButton:false,
                  timer:2000,
                })
                return Promise.reject('Bad Gateway');
            }
            else if (error.response && error.response.status  === 503) {
              Swal.fire({
                title:"Servizio non disponibile",
                text:"Mi spiace, il servizio richiesto è momentaneamente indisponibile.",
                icon:"error",
                showCancelButton:false,
                showConfirmButton:false,
                timer:2000,
              })
                return Promise.reject('Service Unavailable');
            } else {
              console.log(error.response )
              Swal.fire({
                title:"Errore imprevisto",
                text:"Ops! si è verificato un problema...riprova, per favore.",
                icon:"error",
                showCancelButton:false,
                showConfirmButton:false,
                timer:2000,
              })
              
            }

        }
      );
      
    },
    onRoutechange(ele) {
      this.initActiveMenu(ele.path);
      if (document.getElementsByClassName("mm-active").length > 0) {
        const currentPosition = document.getElementsByClassName("mm-active")[0].offsetTop;
        if (currentPosition > 500)
          if (this.$refs.isSimplebar)
            this.$refs.isSimplebar.value.getScrollElement().scrollTop = currentPosition + 300;
      }
    },

    initActiveMenu(ele) {
      setTimeout(() => {
        if (document.querySelector("#navbar-nav")) {
          let a = document.querySelector("#navbar-nav").querySelector('[href="' + ele + '"]');
          if (a) {
            a.classList.add("active");
            let parentCollapseDiv = a.closest(".collapse.menu-dropdown");
            if (parentCollapseDiv) {
              parentCollapseDiv.classList.add("show");
              parentCollapseDiv.parentElement.children[0].classList.add("active");
              parentCollapseDiv.parentElement.children[0].setAttribute("aria-expanded", "true");
              if (parentCollapseDiv.parentElement.closest(".collapse.menu-dropdown")) {
                parentCollapseDiv.parentElement.closest(".collapse").classList.add("show");
                if (parentCollapseDiv.parentElement.closest(".collapse").previousElementSibling)
                  parentCollapseDiv.parentElement.closest(".collapse").previousElementSibling.classList.add("active");
                const grandparent = parentCollapseDiv.parentElement.closest(".collapse").previousElementSibling.parentElement.closest(".collapse");
                if(grandparent && grandparent.previousElementSibling){
                  grandparent.previousElementSibling.classList.add("active");
                  grandparent.classList.add("show");
                }
              }
            }
          }
        }
      }, 0);
    },
    getMenu(){
      if ( localStorage.getItem('tk') ) {
          this.axiosInterceptor()
          axios.get(`${serverConfig.EP}settings/get/menu/by-role`, 
            { 
              headers: { 'authorization':'Bearer '+ localStorage.getItem('tk')}
            }
          ).then( response => {
              if (response.data){
                if (response.data.status=='Ok') {
                  this.menulist = response.data.menu
                }
              } 
          })
        }      
    },
    checkActiveMenu(pagename,pagelist) {
      if (pagelist.includes(pagename)==true) {
        return "nav-link menu-link active"
      } else {
        return "nav-link menu-link"
      }
    },
    checkShowMenuDiv(pagename,pagelist) {
      if (pagelist.includes(pagename)==true) {
        return "collapse menu-dropdown show"
      } else {
        return "collapse menu-dropdown"
      }
    },
    checkMenuNavLink(routename,pagename){
      if (routename==pagename) {
        return "nav-link active"
      } else {
        return "nav-link"
      }
    }
  },
  };
  </script>
  
  <template>
    <div class="container-fluid">
      <div id="two-column-menu"></div>
  
      <template v-if="layoutType === 'twocolumn'">
        <SimpleBar class="navbar-nav" id="navbar-nav">
          <li class="menu-title">
            <span data-key="t-menu"> {{ $t("t-menu") }}</span>
          </li>
          <li class="nav-item">
            <a class="nav-link menu-link" href="#sidebarApps" data-bs-toggle="collapse" role="button"
              aria-expanded="false" aria-controls="sidebarApps">
              <i class="ri-apps-2-line"></i>
              <span data-key="t-apps"> {{ $t("t-apps") }}</span>white
            </a>
            <div class="collapse menu-dropdown" id="sidebarApps">
              <ul class="nav nav-sm flex-column">
                <li class="nav-item">
                  <router-link to="/calendar" class="nav-link" data-key="t-calendar">
                    {{ $t("t-calendar") }} sdsa
                  </router-link>
                </li>
                <li class="nav-item">
                  <router-link to="/chat" class="nav-link" data-key="t-chat">
                    {{ $t("t-chat") }}
                  </router-link>
                </li>
                <li class="nav-item">
                  <router-link to="/mailbox" class="nav-link" data-key="t-mailbox">
                    {{ $t("t-mailbox") }}
                  </router-link>
                </li>
                <li class="nav-item">
                  <a href="#sidebarEcommerce" class="nav-link" data-bs-toggle="collapse" role="button"
                    aria-expanded="false" aria-controls="sidebarEcommerce" data-key="t-ecommerce">
                    {{ $t("t-ecommerce") }} sada
                  </a>
                  <div class="collapse menu-dropdown" id="sidebarEcommerce">
                    <ul class="nav nav-sm flex-column">
                      <li class="nav-item">
                        <router-link to="/ecommerce/products" class="nav-link" data-key="t-products">
                          {{ $t("t-products") }}
                        </router-link>
                      </li>
                      <!--<li class="nav-item">
                        <router-link to="/ecommerce/product-details" class="nav-link" data-key="t-product-Details">
                          {{ $t("t-product-Details") }}
                        </router-link>
                      </li>
                      <li class="nav-item">
                        <router-link to="/ecommerce/add-product" class="nav-link" data-key="t-create-product">
                          {{ $t("t-create-product") }}
                        </router-link>
                      </li>-->
                      <li class="nav-item">
                        <router-link to="/ecommerce/orders" class="nav-link" data-key="t-orders">
                          {{ $t("t-orders") }}
                        </router-link>
                      </li>
                      <li class="nav-item">
                        <router-link to="/ecommerce/order-details" class="nav-link" data-key="t-order-details">
                          {{ $t("t-order-details") }}
                        </router-link>
                      </li>

                      <li class="nav-item">
                        <router-link to="/ecommerce/summary-cart" class="nav-link" data-key="t-summary-cart">
                          {{ $t("t-summary-cart") }}
                        </router-link>
                      </li>
                      <li class="nav-item">
                        <router-link to="/ecommerce/checkout" class="nav-link" data-key="t-checkout">
                          {{ $t("t-checkout") }}
                        </router-link>
                      </li>
                      <li class="nav-item">
                        <router-link to="/ecommerce/sellers" class="nav-link" data-key="t-sellers">
                          {{ $t("t-sellers") }}
                        </router-link>
                      </li>
                      <li class="nav-item">
                        <router-link to="/ecommerce/seller-details" class="nav-link" data-key="t-sellers-details">
                          {{ $t("t-sellers-details") }}
                        </router-link>
                      </li>
                    </ul>
                  </div>
                </li>
                <li class="nav-item">
                  <a href="#sidebarProjects" class="nav-link" data-bs-toggle="collapse" role="button"
                    aria-expanded="false" aria-controls="sidebarProjects" data-key="t-projects">
                    {{ $t("t-projects") }}
                  </a>
                  <div class="collapse menu-dropdown" id="sidebarProjects">
                    <ul class="nav nav-sm flex-column">
                      <li class="nav-item">
                        <router-link to="/apps/projects-list" class="nav-link" data-key="t-list">
                          {{ $t("t-list") }}
                        </router-link>
                      </li>
                      <li class="nav-item">
                        <router-link to="/apps/projects-overview" class="nav-link" data-key="t-overview">
                          {{ $t("t-overview") }}
                        </router-link>
                      </li>
                      <li class="nav-item">
                        <router-link to="/apps/projects-create" class="nav-link" data-key="t-create-project">
                          {{ $t("t-create-project") }}
                        </router-link>
                      </li>
                    </ul>
                  </div>
                </li>
                <li class="nav-item">
                  <a href="#sidebarTasks" class="nav-link" data-bs-toggle="collapse" role="button" aria-expanded="false"
                    aria-controls="sidebarTasks" data-key="t-tasks">
                    {{ $t("t-tasks") }}
                  </a>
                  <div class="collapse menu-dropdown" id="sidebarTasks">
                    <ul class="nav nav-sm flex-column">
                      <li class="nav-item">
                        <router-link to="/apps/tasks-kanban" class="nav-link" data-key="t-kanbanboard">
                          {{ $t("t-kanbanboard") }}
                        </router-link>
                      </li>
                      <li class="nav-item">
                        <router-link to="/apps/tasks-list-view" class="nav-link" data-key="t-list-view">
                          {{ $t("t-list-view") }}
                        </router-link>
                      </li>
                      <li class="nav-item">
                        <router-link to="/apps/tasks-details" class="nav-link" data-key="t-task-details">
                          {{ $t("t-task-details") }}
                        </router-link>
                      </li>
                    </ul>
                  </div>
                </li>
                <li class="nav-item">
                  <a href="#sidebarCRM" class="nav-link" data-bs-toggle="collapse" role="button" aria-expanded="false"
                    aria-controls="sidebarCRM" data-key="t-crm">
                    {{ $t("t-crm") }}
                  </a>
                  <div class="collapse menu-dropdown" id="sidebarCRM">
                    <ul class="nav nav-sm flex-column">
                      <li class="nav-item">
                        <router-link to="/apps/crm-contacts" class="nav-link" data-key="t-contacts">
                          {{ $t("t-contacts") }}
                        </router-link>
                      </li>
                      <li class="nav-item">
                        <router-link to="/apps/crm-companies" class="nav-link" data-key="t-companies">
                          {{ $t("t-companies") }}
                        </router-link>
                      </li>
                      <li class="nav-item">
                        <router-link to="/apps/crm-deals" class="nav-link" data-key="t-deals">
                          {{ $t("t-deals") }}
                        </router-link>
                      </li>
                      <li class="nav-item">
                        <router-link to="/apps/crm-leads" class="nav-link" data-key="t-leads">
                          {{ $t("t-leads") }}
                        </router-link>
                      </li>
                    </ul>
                  </div>
                </li>

                <li class="nav-item">
                  <a href="#sidebarInvoices" class="nav-link" data-bs-toggle="collapse" role="button"
                    aria-expanded="false" aria-controls="sidebarInvoices" data-key="t-invoices">
                    {{ $t("t-invoices") }} 
                  </a>
                  <div class="collapse menu-dropdown" id="sidebarInvoices">
                    <ul class="nav nav-sm flex-column">
                      <li class="nav-item">
                        <router-link to="/invoices/list" class="nav-link" data-key="t-list-view">
                          {{ $t("t-list-view") }}
                        </router-link>
                      </li>
                      <li class="nav-item">
                        <router-link to="/invoices/detail" class="nav-link" data-key="t-details">
                          {{ $t("t-details") }}
                        </router-link>
                      </li>
                      <li class="nav-item">
                        <router-link to="/invoices/create" class="nav-link" data-key="t-create-invoice">
                          {{ $t("t-create-invoice") }}
                        </router-link>
                      </li>
                    </ul>
                  </div>
                </li>
                <li class="nav-item">
                  <a href="#sidebarTickets" class="nav-link" data-bs-toggle="collapse" role="button" aria-expanded="false"
                    aria-controls="sidebarTickets" data-key="t-supprt-tickets">
                    {{ $t("t-supprt-tickets") }}
                  </a>
                  <div class="collapse menu-dropdown" id="sidebarTickets">
                    <ul class="nav nav-sm flex-column">
                      <li class="nav-item">
                        <router-link to="/apps/tickets-list" class="nav-link" data-key="t-list-view">
                          {{ $t("t-list-view") }}
                        </router-link>
                      </li>
                      <li class="nav-item">
                        <router-link to="/apps/tickets-details" class="nav-link" data-key="t-ticket-details">
                          {{ $t("t-ticket-details") }}
                        </router-link>
                      </li>
                    </ul>
                  </div>
                </li>
              </ul>
            </div>
          </li>
         
 
          <li class="nav-item">
            <a class="nav-link menu-link" href="#sidebarUI" data-bs-toggle="collapse" role="button" aria-expanded="false"
              aria-controls="sidebarUI">
              <i class="ri-pencil-ruler-2-line"></i>
              <span data-key="t-base-ui">{{ $t("t-base-ui") }} sdsadas</span>
            </a>
            <div class="collapse menu-dropdown mega-dropdown-menu" id="sidebarUI">
              <div class="row">
                <div class="col-lg-4">
                  <ul class="nav nav-sm flex-column">
                    <li class="nav-item">
                      <router-link to="/ui/alerts" class="nav-link" data-key="t-alerts">{{ $t("t-alerts") }}</router-link>
                    </li>
                    <li class="nav-item">
                      <router-link to="/ui/badges" class="nav-link" data-key="t-badges">{{ $t("t-badges") }}</router-link>
                    </li>
                    <li class="nav-item">
                      <router-link to="/ui/buttons" class="nav-link" data-key="t-buttons">{{ $t("t-buttons") }}
                      </router-link>
                    </li>
                    <li class="nav-item">
                      <router-link to="/ui/colors" class="nav-link" data-key="t-colors">{{ $t("t-colors") }}</router-link>
                    </li>
                    <li class="nav-item">
                      <router-link to="/ui/cards" class="nav-link" data-key="t-cards">{{ $t("t-cards") }}</router-link>
                    </li>
                    <li class="nav-item">
                      <router-link to="/ui/carousel" class="nav-link" data-key="t-carousel">{{ $t("t-carousel") }}
                      </router-link>
                    </li>
                    <li class="nav-item">
                      <router-link to="/ui/dropdowns" class="nav-link" data-key="t-dropdowns">{{ $t("t-dropdowns") }}
                      </router-link>
                    </li>
                    <li class="nav-item">
                      <router-link to="/ui/grid" class="nav-link" data-key="t-grid">{{ $t("t-grid") }}</router-link>
                    </li>
                  </ul>
                </div>
                <div class="col-lg-4">
                  <ul class="nav nav-sm flex-column">
                    <li class="nav-item">
                      <router-link to="/ui/images" class="nav-link" data-key="t-images">{{ $t("t-images") }}</router-link>
                    </li>
                    <li class="nav-item">
                      <router-link to="/ui/tabs" class="nav-link" data-key="t-tabs">{{ $t("t-tabs") }}</router-link>
                    </li>
                    <li class="nav-item">
                      <router-link to="/ui/accordions" class="nav-link" data-key="t-accordion-collapse">
                        {{ $t("t-accordion-collapse") }}</router-link>
                    </li>
                    <li class="nav-item">
                      <router-link to="/ui/modals" class="nav-link" data-key="t-modals">{{ $t("t-modals") }}</router-link>
                    </li>
                    <li class="nav-item">
                      <router-link to="/ui/offcanvas" class="nav-link" data-key="t-offcanvas">{{ $t("t-offcanvas") }}
                      </router-link>
                    </li>
                    <li class="nav-item">
                      <router-link to="/ui/placeholders" class="nav-link" data-key="t-placeholders">
                        {{ $t("t-placeholders") }}</router-link>
                    </li>
                    <li class="nav-item">
                      <router-link to="/ui/progress" class="nav-link" data-key="t-progress">{{ $t("t-progress") }}
                      </router-link>
                    </li>
                    <li class="nav-item">
                      <router-link to="/ui/notifications" class="nav-link" data-key="t-notifications">
                        {{ $t("t-notifications") }}</router-link>
                    </li>
                  </ul>
                </div>
                <div class="col-lg-4">
                  <ul class="nav nav-sm flex-column">
                    <li class="nav-item">
                      <router-link to="/ui/media" class="nav-link" data-key="t-media-object">{{ $t("t-media-object") }}
                      </router-link>
                    </li>
                    <li class="nav-item">
                      <router-link to="/ui/embed-video" class="nav-link" data-key="t-embed-video">
                        {{ $t("t-embed-video") }}</router-link>
                    </li>
                    <li class="nav-item">
                      <router-link to="/ui/typography" class="nav-link" data-key="t-typography">{{ $t("t-typography") }}
                      </router-link>
                    </li>
                    <li class="nav-item">
                      <router-link to="/ui/lists" class="nav-link" data-key="t-lists">{{ $t("t-lists") }}</router-link>
                    </li>
                    <li class="nav-item">
                      <router-link to="/ui/general" class="nav-link" data-key="t-general">{{ $t("t-general") }}
                      </router-link>
                    </li>
                    <li class="nav-item">
                      <router-link to="/ui/ribbons" class="nav-link" data-key="t-ribbons">{{ $t("t-ribbons") }}
                      </router-link>
                    </li>
                    <li class="nav-item">
                      <router-link to="/ui/utilities" class="nav-link" data-key="t-utilities">{{ $t("t-utilities") }}
                      </router-link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </li>
  
          <li class="nav-item">
            <a class="nav-link menu-link" href="#sidebarAdvanceUI" data-bs-toggle="collapse" role="button"
              aria-expanded="false" aria-controls="sidebarAdvanceUI">
              <i class="ri-stack-line"></i>
              <span data-key="t-advance-ui">{{ $t("t-advance-ui") }}</span>
              <span class="badge badge-pill bg-success" data-key="t-new">{{
              $t("t-new")
              }}</span>
            </a>
            <div class="collapse menu-dropdown" id="sidebarAdvanceUI">
              <ul class="nav nav-sm flex-column">
                <li class="nav-item">
                  <router-link to="/advance-ui/sweetalerts" class="nav-link" data-key="t-sweet-alerts">
                    {{ $t("t-sweet-alerts") }}</router-link>
                </li>
  
                <li class="nav-item">
                  <router-link to="/advance-ui/scrollbar" class="nav-link" data-key="t-scrollbar">{{ $t("t-scrollbar") }}
                  </router-link>
                </li>
                <li class="nav-item">
                  <router-link to="/advance-ui/animation" class="nav-link" data-key="t-animation">{{ $t("t-animation") }}
                  </router-link>
                </li>
                <li class="nav-item">
                  <router-link to="/advance-ui/swiper" class="nav-link" data-key="t-swiper-slider">
                    {{ $t("t-swiper-slider") }}</router-link>
                </li>
  
                <li class="nav-item">
                  <router-link to="/advance-ui/highlight" class="nav-link" data-key="t-highlight">{{ $t("t-highlight") }}
                  </router-link>
                </li>
                <li class="nav-item">
                  <router-link to="/advance-ui/scrollspy" class="nav-link" data-key="t-scrollSpy">{{ $t("t-scrollSpy") }}
                  </router-link>
                </li>
              </ul>
            </div>
          </li>
  
          <li class="nav-item">
            <router-link class="nav-link menu-link" to="/widgets">
              <i class="ri-honour-line"></i>
              <span data-key="t-widgets">{{ $t("t-widgets") }}</span>
            </router-link>
          </li>
  
          <li class="nav-item">
            <a class="nav-link menu-link" href="#sidebarForms" data-bs-toggle="collapse" role="button"
              aria-expanded="false" aria-controls="sidebarForms">
              <i class="ri-file-list-3-line"></i>
              <span data-key="t-forms">{{ $t("t-forms") }}</span>
            </a>
            <div class="collapse menu-dropdown" id="sidebarForms">
              <ul class="nav nav-sm flex-column">
                <li class="nav-item">
                  <router-link to="/form/elements" class="nav-link" data-key="t-basic-elements">
                    {{ $t("t-basic-elements") }}</router-link>
                </li>
                <li class="nav-item">
                  <router-link to="/form/select" class="nav-link" data-key="t-form-select">{{ $t("t-form-select") }}
                  </router-link>
                </li>
                <li class="nav-item">
                  <router-link to="/form/checkboxs-radios" class="nav-link" data-key="t-checkboxs-radios">
                    {{ $t("t-checkboxs-radios") }}</router-link>
                </li>
                <li class="nav-item">
                  <router-link to="/form/pickers" class="nav-link" data-key="t-pickers">
                    {{ $t("t-pickers") }}
                  </router-link>
                </li>
                <li class="nav-item">
                  <router-link to="/form/masks" class="nav-link" data-key="t-input-masks">{{ $t("t-input-masks") }}
                  </router-link>
                </li>
                <li class="nav-item">
                  <router-link to="/form/advanced" class="nav-link" data-key="t-advanced">
                    {{ $t("t-advanced") }}</router-link>
                </li>
                <li class="nav-item">
                  <router-link to="/form/range-sliders" class="nav-link" data-key="t-range-slider">
                    {{ $t("t-range-slider") }}
                  </router-link>
                </li>
                <li class="nav-item">
                  <router-link to="/form/validation" class="nav-link" data-key="t-validation">{{ $t("t-validation") }}
                  </router-link>
                </li>
                <li class="nav-item">
                  <router-link to="/form/wizard" class="nav-link" data-key="t-wizard">{{ $t("t-wizard") }}</router-link>
                </li>
                <li class="nav-item">
                  <router-link to="/form/editors" class="nav-link" data-key="t-editors">{{ $t("t-editors") }}
                  </router-link>
                </li>
                <li class="nav-item">
                  <router-link to="/form/file-uploads" class="nav-link" data-key="t-file-uploads">
                    {{ $t("t-file-uploads") }}</router-link>
                </li>
                <li class="nav-item">
                  <router-link to="/form/layouts" class="nav-link" data-key="t-form-layouts">{{ $t("t-form-layouts") }}
                  </router-link>
                </li>
              </ul>
            </div>
          </li>
  
          <li class="nav-item">
            <a class="nav-link menu-link" href="#sidebarTables" data-bs-toggle="collapse" role="button"
              aria-expanded="false" aria-controls="sidebarTables">
              <i class="ri-layout-grid-line"></i>
              <span data-key="t-tables">{{ $t("t-tables") }}</span>
            </a>
            <div class="collapse menu-dropdown" id="sidebarTables">
              <ul class="nav nav-sm flex-column">
                <li class="nav-item">
                  <router-link to="/tables/basic" class="nav-link" data-key="t-basic-tables">{{ $t("t-basic-tables") }}
                  </router-link>
                </li>
                <li class="nav-item">
                  <router-link to="/tables/gridjs" class="nav-link" data-key="t-grid-js">{{ $t("t-grid-js") }}
                  </router-link>
                </li>
              </ul>
            </div>
          </li>
  
          <li class="nav-item">
            <a class="nav-link menu-link" href="#sidebarCharts" data-bs-toggle="collapse" role="button"
              aria-expanded="false" aria-controls="sidebarCharts">
              <i class="ri-pie-chart-line"></i>
              <span data-key="t-charts">{{ $t("t-charts") }}</span>
            </a>
            <div class="collapse menu-dropdown" id="sidebarCharts">
              <ul class="nav nav-sm flex-column">
                <li class="nav-item">
                  <a href="#sidebarApexcharts" class="nav-link" data-bs-toggle="collapse" role="button"
                    aria-expanded="false" aria-controls="sidebarApexcharts" data-key="t-apexcharts">
                    {{ $t("t-apexcharts") }}
                  </a>
                  <div class="collapse menu-dropdown" id="sidebarApexcharts">
                    <ul class="nav nav-sm flex-column">
                      <li class="nav-item">
                        <router-link to="/charts/apex-line" class="nav-link" data-key="t-line">
                          {{ $t("t-line") }}
                        </router-link>
                      </li>
                      <li class="nav-item">
                        <router-link to="/charts/apex-area" class="nav-link" data-key="t-area">
                          {{ $t("t-area") }}
                        </router-link>
                      </li>
                      <li class="nav-item">
                        <router-link to="/charts/apex-column" class="nav-link" data-key="t-column">
                          {{ $t("t-column") }}
                        </router-link>
                      </li>
                      <li class="nav-item">
                        <router-link to="/charts/apex-bar" class="nav-link" data-key="t-bar">
                          {{ $t("t-bar") }}
                        </router-link>
                      </li>
                      <li class="nav-item">
                        <router-link to="/charts/apex-mixed" class="nav-link" data-key="t-mixed">
                          {{ $t("t-mixed") }}
                        </router-link>
                      </li>
  
                      <li class="nav-item">
                        <router-link to="/charts/apex-candlestick" class="nav-link" data-key="t-candlstick">
                          {{ $t("t-candlstick") }}
                        </router-link>
                      </li>
                      <li class="nav-item">
                        <router-link to="/charts/apex-boxplot" class="nav-link" data-key="t-boxplot">
                          {{ $t("t-boxplot") }}
                        </router-link>
                      </li>
                      <li class="nav-item">
                        <router-link to="/charts/apex-bubble" class="nav-link" data-key="t-bubble">
                          {{ $t("t-bubble") }}
                        </router-link>
                      </li>
                      <li class="nav-item">
                        <router-link to="/charts/apex-scatter" class="nav-link" data-key="t-scatter">
                          {{ $t("t-scatter") }}
                        </router-link>
                      </li>
                      <li class="nav-item">
                        <router-link to="/charts/apex-heatmap" class="nav-link" data-key="t-heatmap">
                          {{ $t("t-heatmap") }}
                        </router-link>
                      </li>
                      <li class="nav-item">
                        <router-link to="/charts/apex-treemap" class="nav-link" data-key="t-treemap">
                          {{ $t("t-treemap") }}
                        </router-link>
                      </li>
                      <li class="nav-item">
                        <router-link to="/charts/apex-pie" class="nav-link" data-key="t-pie">
                          {{ $t("t-pie") }}
                        </router-link>
                      </li>
                      <li class="nav-item">
                        <router-link to="/charts/apex-radialbar" class="nav-link" data-key="t-radialbar">
                          {{ $t("t-radialbar") }}
                        </router-link>
                      </li>
                      <li class="nav-item">
                        <router-link to="/charts/apex-radar" class="nav-link" data-key="t-radar">
                          {{ $t("t-radar") }}
                        </router-link>
                      </li>
                      <li class="nav-item">
                        <router-link to="/charts/apex-polararea" class="nav-link" data-key="t-polar-area">
                          {{ $t("t-polar-area") }}
                        </router-link>
                      </li>
                    </ul>
                  </div>
                </li>
                <li class="nav-item">
                  <router-link to="/charts/chartjs" class="nav-link" data-key="t-chartjs">{{ $t("t-chartjs") }}
                  </router-link>
                </li>
                <li class="nav-item">
                  <router-link to="/charts/echart" class="nav-link" data-key="t-echarts">{{ $t("t-echarts") }}
                  </router-link>
                </li>
              </ul>
            </div>
          </li>
  
          <li class="nav-item">
            <a class="nav-link menu-link" href="#sidebarIcons" data-bs-toggle="collapse" role="button"
              aria-expanded="false" aria-controls="sidebarIcons">
              <i class="ri-compasses-2-line"></i>
              <span data-key="t-icons">{{ $t("t-icons") }}</span>
            </a>
            <div class="collapse menu-dropdown" id="sidebarIcons">
              <ul class="nav nav-sm flex-column">
                <li class="nav-item">
                  <router-link to="/icons/remix" class="nav-link" data-key="t-remix">{{ $t("t-remix") }}</router-link>
                </li>
                <li class="nav-item">
                  <router-link to="/icons/boxicons" class="nav-link" data-key="t-boxicons">{{ $t("t-boxicons") }}
                  </router-link>
                </li>
                <li class="nav-item">
                  <router-link to="/icons/materialdesign" class="nav-link" data-key="t-material-design">
                    {{ $t("t-material-design") }}</router-link>
                </li>
                <li class="nav-item">
                  <router-link to="/icons/lineawesome" class="nav-link" data-key="t-line-awesome">
                    {{ $t("t-line-awesome") }}</router-link>
                </li>
                <li class="nav-item">
                  <router-link to="/icons/feather" class="nav-link" data-key="t-feather">{{ $t("t-feather") }}
                  </router-link>
                </li>
              </ul>
            </div>
          </li>
  
          <li class="nav-item">
            <a class="nav-link menu-link" href="#sidebarMaps" data-bs-toggle="collapse" role="button"
              aria-expanded="false" aria-controls="sidebarMaps">
              <i class="ri-map-pin-line"></i>
              <span data-key="t-maps">{{ $t("t-maps") }}</span>
            </a>
            <div class="collapse menu-dropdown" id="sidebarMaps">
              <ul class="nav nav-sm flex-column">
                <li class="nav-item">
                  <router-link to="/maps/google" class="nav-link" data-key="t-google">
                    {{ $t("t-google") }}
                  </router-link>
                </li>
                <li class="nav-item">
                  <router-link to="/maps/leaflet" class="nav-link" data-key="t-leaflet">
                    {{ $t("t-leaflet") }}
                  </router-link>
                </li>
              </ul>
            </div>
          </li>
  
          <li class="nav-item">
            <a class="nav-link menu-link" href="#sidebarMultilevel" data-bs-toggle="collapse" role="button"
              aria-expanded="false" aria-controls="sidebarMultilevel">
              <i class="ri-share-line"></i>
              <span data-key="t-multi-level">{{ $t("t-multi-level") }}</span>
            </a>
            <div class="collapse menu-dropdown" id="sidebarMultilevel">
              <ul class="nav nav-sm flex-column">
                <li class="nav-item">
                  <a href="#" class="nav-link" data-key="t-level-1.1">
                    {{ $t("t-level-1.1") }}
                  </a>
                </li>
                <li class="nav-item">
                  <a href="#sidebarAccount" class="nav-link" data-bs-toggle="collapse" role="button" aria-expanded="false"
                    aria-controls="sidebarAccount" data-key="t-level-1.2">
                    {{ $t("t-level-1.2") }}
                  </a>
                  <div class="collapse menu-dropdown" id="sidebarAccount">
                    <ul class="nav nav-sm flex-column">
                      <li class="nav-item">
                        <a href="#" class="nav-link" data-key="t-level-2.1">
                          {{ $t("t-level-2.1") }}
                        </a>
                      </li>
                      <li class="nav-item">
                        <a href="#sidebarCrm" class="nav-link" data-bs-toggle="collapse" role="button"
                          aria-expanded="false" aria-controls="sidebarCrm" data-key="t-level-2.2">
                          {{ $t("t-level-2.2") }}
                        </a>
                        <div class="collapse menu-dropdown" id="sidebarCrm">
                          <ul class="nav nav-sm flex-column">
                            <li class="nav-item">
                              <a href="#" class="nav-link" data-key="t-level-3.1">
                                {{ $t("t-level-3.1") }}
                              </a>
                            </li>
                            <li class="nav-item">
                              <a href="#" class="nav-link" data-key="t-level-3.2">
                                {{ $t("t-level-3.2") }}
                              </a>
                            </li>
                          </ul>
                        </div>
                      </li>
                    </ul>
                  </div>
                </li>
              </ul>
            </div>
          </li>
        </SimpleBar>
      </template>
  
      <template v-else>
        <ul class="navbar-nav h-100" id="navbar-nav">
          <li class="menu-title">
            <span data-key="t-menu"> {{ $t("t-menu") }}</span>               
          </li>

          <li class="nav-item" v-for="item in menulist" :key="item._id">
            
            <template v-if="item.parent==''">
              <a :href="`#${item.divID}`" :class="checkActiveMenu($route.name,item.routeNameList)" data-bs-toggle="collapse" role="button" aria-expanded="false" :aria-controls="`${item.divID}`" :data-key="`${item.divID}`">
                <i :class="`${item.icon}`"></i>
                <span :data-key="`${item.link_data_key}`"> {{ item.link_text  }}</span>
              </a>
            </template>
            <template v-if="item.child.length>0">
              <div :class="checkShowMenuDiv($route.name,item.routeNameList)" :id="`${item.divID}`">
                <ul class="nav nav-sm flex-column">
                  <li class="nav-item" v-for="subitem in item.child" :key="subitem">

                    <router-link :to="`${subitem.link_to}`" :class="checkMenuNavLink($route.name, subitem.routeName)" :data-key="`${subitem.link_data_key}`">
                    {{  subitem.link_text }}
                    </router-link>
                    <template v-if="subitem.child.length>0">
                      <ul class="nav nav-sm flex-column">
                        <li class="nav-item" v-for="subitem_c in subitem.child" :key="subitem_c">     
                          <template v-if="$route.params.id">
                            <template v-if="$route.name==subitem_c.routeName">
                              <template v-if="Object.keys($route.params).length==1">
                                <router-link :to="`${subitem_c.link_to}${$route.params.id}`" :class="checkMenuNavLink($route.name, subitem_c.routeName)" :data-key="`${subitem_c.routeName}`">
                                  {{  subitem_c.link_text }}
                                </router-link>
                              </template>
                              <template v-else-if="Object.keys($route.params).length==2">
                                <router-link :to="`${subitem_c.link_to}${$route.params.id}/${$route.params[ Object.keys($route.params)[0] ] }`" :class="checkMenuNavLink($route.name, subitem_c.routeName)" :data-key="`${subitem_c.routeName}`">
                                  {{  subitem_c.link_text }}
                                </router-link>
                              </template>  
                              <template v-else-if="Object.keys($route.params).length==3">
                                <router-link :to="`${subitem_c.link_to}${$route.params.id}/${$route.params[ Object.keys($route.params)[1] ] }/${$route.params[ Object.keys($route.params)[2] ]}`" :class="checkMenuNavLink($route.name, subitem_c.routeName)" :data-key="`${subitem_c.routeName}`">
                                  {{  subitem_c.link_text }}
                                </router-link>
                              </template>  
                            </template>
                          </template>
                        </li>
                      </ul>
                    </template>
                  </li>
                </ul>
              </div>
            </template>

          </li>


        </ul>
      </template>
    </div>
    <!-- Sidebar -->
  </template>